exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invisalign-js": () => import("./../../../src/pages/invisalign.js" /* webpackChunkName: "component---src-pages-invisalign-js" */),
  "component---src-pages-new-patients-js": () => import("./../../../src/pages/new-patients.js" /* webpackChunkName: "component---src-pages-new-patients-js" */),
  "component---src-pages-services-adult-braces-js": () => import("./../../../src/pages/services/adult-braces.js" /* webpackChunkName: "component---src-pages-services-adult-braces-js" */),
  "component---src-pages-services-kids-braces-js": () => import("./../../../src/pages/services/kids-braces.js" /* webpackChunkName: "component---src-pages-services-kids-braces-js" */),
  "component---src-pages-services-types-of-braces-js": () => import("./../../../src/pages/services/types-of-braces.js" /* webpackChunkName: "component---src-pages-services-types-of-braces-js" */)
}

