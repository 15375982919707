module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"land orthodontics","short_name":"land orthodontics","start_url":"/","background_color":"#4ECDC4","display":"minimal-ui","icon":"src/images/website-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"546d4076116621a4acf42034a3f48431"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
